<template>
<div>
	
	<div class="user-info box" v-if="$store.state.token">
      <div class="user-info-name">用户：{{userInfo.user_name}}</div>
      <div>号码：{{userInfo.vonage_number}}</div>
      <br>
      <el-button type="primary" @click="logout">退出</el-button>
	</div>
  
  <div class="login box" v-else>
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="auto" hide-required-asterisk>
        <el-form-item label="账号" prop="account">
          <el-input v-model="form.account" :validate-event="false"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input :type="passwordType" v-model="form.pass" :validate-event="false"></el-input>
          <i class="el-icon-view" @click="showPwd"></i>
        </el-form-item>
         <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
      </el-form>
    </div>
	
</div>
</template>

<script>
import { login, logout } from '@/api/user.js'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      passwordType: 'password',
      form: {
        account: '',
        pass: ''
      },
      rules: {
        account: [
          { required: true }
        ],
        pass: [
          { required: true }
        ]
      },
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
	get_token(){
		return this.$store.state.token
	}
  },
  watch:{
	// 当手机浏览器设置清除Cookies时,监听token,退出登录
    get_token(){
	  if(!this.$store.state.token){
		logout({ id: this.$store.state.userInfo.user_id }).then((res) => {
			this.$store.commit('clearToken')
			location.reload()
		    Message({message: "请先登录",duration: 1 * 1000, })
		})
	  }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            user_account: this.form.account,
            user_pwd: this.form.pass
          }
          login(data).then(res => {
            this.$store.commit('setToken', res.data.token)
            const userInfo = {
              user_name: res.data.user_name,
              vonage_number: res.data.vonage_number,
              user_id: res.data.id
            }
            this.$store.commit('setUserInfo', userInfo)
            this.$router.push('/communication')
          })
        } else {
          return false
        }
      })
    },
    showPwd() {
      this.passwordType = this.passwordType ? '' : 'password'
    },
    logout() {
		logout({ id: this.$store.state.userInfo.user_id }).then((res) => {
			this.$store.commit('clearToken')
		    this.$msgOk('退出成功')
			location.reload()
		  }).catch((err) => {
		    this.$msgError('退出错误')
			location.reload()
		})
      // logout({ id: this.$store.state.userInfo.user_id }).finally(() => {
      //   this.$store.state.client.logout().then(() => {
      //     this.$msgOk('退出成功')
      //     this.$store.commit('clearToken')
      //     // location.reload()
      //   }).catch(() => {
      //     this.$msgError('退出错误')
      //     this.$store.commit('clearToken')
      //     // location.reload()
      //   })
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.box{
  margin: 200px auto 0 auto;
  padding: 50px;
  width: 300px;
  background-color: #fff;
  .el-icon-view{
    position: absolute;
    right: 10px;
    top: 12px;
    font-size: 16px;
    color: #C0C4CC;
    cursor: pointer;
    user-select: none;
  }
}
.user-info{
  .user-info-name{
    margin-bottom: 15px;
  }
}
</style>
