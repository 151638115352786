import request from '@/plugins/axios'

export const login = (data) => request({
  method: 'post',
  url: 'jwt',
  data
})

export const logout = (data) => request({
  method: 'post',
  url: 'logout',
  data
})

export const vonageStatus = (data) => request({
  method: 'post',
  url: 'status',
  data
})

export const vonageAnswerTime = (data) => request({
  method: 'post',
  url: 'answer/time',
  data
})